import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createEvento } from "../redux/actions";
import Add from "../assets/Agregar.png";
import Cloudinary from "./Cloudinary";
import Swal from "sweetalert2";
import ModificarEvento from "./ModificarEvento";

const DashboardEventos = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false)
  const [errors, setErrors] = useState(false);
  const [errorImg, setErrorImg] = useState(false);
  const [secureURLs, setSecureURLs] = useState([]);
  const [secureURLsMaps, setSecureURLsMaps] = useState([]);
  
  
  
  const [addCorredor, setAddCorredor] = useState({
    id: "",
    titulo: "",
    imagenes: [],
    imagenesmapas: [],
    urlInscripcion: "",
    fecha: "",
    ubicacion: "",
    distancia: "",
    desnivelPositivo: "",
    consideracionesGenerales: "",
  });
  

  function validate(addCorredor) {
    let errors = {};
  
    if (addCorredor.titulo === "") {
      errors.titulo = "Inserte un nombre para el producto";
    } 
    if (addCorredor.ubicacion === "") {
      errors.ubicacion = "Inserte una ubicacion para el Evento";
    }
    if (addCorredor.urlInscripcion === "") {
      errors.ubicacion = "Inserte un Link de Inscripcion para el Evento";
    }
    if (addCorredor.distancia === "") {
      errors.ubicacion = "Inserte distancias para el Evento";
    }
    if (addCorredor.desnivelPositivo === "") {
      errors.ubicacion = "Inserte un desnivel Positivo para el Evento";
    }
    if (addCorredor.consideracionesGenerales === "") {
      errors.ubicacion =
      "Inserte una consideracion general (descripcion) para el Evento";
    }
    return errors;
  }
  
  function handleChange(e) {
    setAddCorredor({
      ...addCorredor,
      [e.target.name]: e.target.value,
    });
    setErrors(
      validate({
        ...addCorredor,
        [e.target.name]: e.target.value,
      })
    );
  }
  
  addCorredor.imagenes = secureURLs
  addCorredor.imagenesmapas = secureURLsMaps 
  
  
  function postEvento() {
    if (addCorredor.imagenes.length === 0) {
      setErrorImg(!errorImg);
    }
    if (
      errors.titulo ||
      errors.urlInscripcion ||
      errors.fecha ||
      errors.ubicacion ||
      errors.distancia ||
      errors.desnivelPositivo ||
      errors.consideracionesGenerales 
    ) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Error, corrija los errores!",
        showConfirmButton: false,
        timer: 1000,
      });
    } else {
      
      dispatch(createEvento(addCorredor));
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Evento agregado!",
        showConfirmButton: false,
        timer: 3000,
      });
      setAddCorredor({
        titulo: "",
        imagenes: [],
        imagenesmapas: [],
        urlInscripcion: "",
        fecha: "",
        ubicacion: "",
        distancia: "",
        desnivelPositivo: "",
        consideracionesGenerales: "",
      });
    }
  }

  
  return (
    <div className="container py-8 mx-auto flex flex-row mb-40">
      <div className="mx-auto justify-start w-[20%] gap-6">
      <button onClick={() => setShowForm(true)} className="flex flex-row px-3 py-2 text-black transition-colors duration-300 transform rounded-lg hover:bg-gray-400 hover:text-white">
        <img src={Add} alt="" className="h-[20px] mr-1" />
        <span className="mx-2 text-sm font-medium">Nuevo evento</span>
      </button>
      <button onClick={() => setShowForm(false)} className="flex flex-row px-3 py-2 text-black transition-colors duration-300 transform rounded-lg hover:bg-gray-400 hover:text-white">
        <img src={Add} alt="" className="h-[20px] mr-1" />
        <span className="mx-2 text-sm font-medium">Modificar evento</span>
      </button>
      </div>
      <div className="w-[80%] grid">
          {showForm ? (
            <div className="flex w-full justify-center my-24">
            <div className="w-[70%]">
              <h1 className="lg:text-3xl md:text-2xl sm:text-xl xs:text-xl font-serif font-extrabold mb-2 text-black">
                Nuevo evento
              </h1>
              <form>
                <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-4 justify-center w-full">
                  <div className="w-full  mb-4 mt-6">
                    <label for="" className="mb-2 text-sm text-black">
                      Titulo
                    </label>
                    <input
                      type="text"
                      name="titulo"
                      className="mt-2 p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                      onChange={(e) => handleChange(e)}
                      value={addCorredor.titulo}
                      />
                  </div>
      
                  <div className="w-full  mb-4 mt-6">
                    <label for="" className="mb-2 text-sm text-black">
                    Url de inscripción
                    </label>
                    <input
                      type="text"
                      name="urlInscripcion"
                      className="mt-2 p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                      onChange={(e) => handleChange(e)}
                      value={addCorredor.urlInscripcion}
                      />
                  </div>
                  <div className="w-full  mb-4 mt-6">
                    <label for="" className="mb-2 text-sm text-black">
                      Fecha
                    </label>
                    <input
                      type="date"
                      name="fecha"
                      className="mt-2 p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                      onChange={(e) => handleChange(e)}
                      value={addCorredor.fecha}
                      />
                  </div>
                  <div className="w-full  mb-4 lg:mt-6">
                    <label for="" className="text-sm text-black">
                      Ubicación
                    </label>
                    <input
                      type="text"
                      name="ubicacion"
                      className="mt-2 p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                      onChange={(e) => handleChange(e)}
                      value={addCorredor.ubicacion}
                      />
                  </div>
                  <div className="w-full  mb-4 lg:mt-6">
                    <label for="" className="text-sm text-black">
                    Consideraciones generales
                    </label>
                    <input
                      type="text"
                      name="consideracionesGenerales"
                      className="mt-2 p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                      onChange={(e) => handleChange(e)}
                      value={addCorredor.consideracionesGenerales}
                      />
                  </div>
                  <div className="w-full  mb-4 lg:mt-6">
                    <label for="" className="text-sm text-black">
                    Desnivel positivo
                    </label>
                    <input
                      type="text"
                      name="desnivelPositivo"
                      className="mt-2 p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                      onChange={(e) => handleChange(e)}
                      value={addCorredor.desnivelPositivo}
                      />
                  </div>
                  <div className="w-full mb-4 lg:mt-6">
                    <label for="" className="text-sm text-black">
                      Distancia
                    </label>
                    <input
                      type="text"
                      className="p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                      name="distancia"
                      onChange={(e) => handleChange(e)}
                      value={addCorredor.distancia}
                      />
                  </div>
                  <div  className="w-full mb-4 lg:mt-6">
                    <label for="" className="text-sm  text-black">
                      Imagenes
                    </label>
      
                    <div>
                      <Cloudinary
                        secureURLs={secureURLs}
                        setSecureURLs={setSecureURLs}
                        />
                    </div>
                  </div>
                  <div  className="w-full mb-4 lg:mt-6">
                    <label for="" className="text-sm mt-2  text-black">
                      Imagenes mapas
                    </label>
      
                    <div>
                      <Cloudinary
                        secureURLs={secureURLsMaps}
                        setSecureURLs={setSecureURLsMaps}
                        />
                    </div>
                  </div>
                </div>
                <div className="w-full rounded-lg bg-blue-500 mt-4 text-white text-lg font-semibold">
                  <button onClick={postEvento} className="w-full p-4">
                    Guardar cambios
                  </button>
                </div>
              </form>
            </div>
          </div>
          ):(
            <ModificarEvento />
          )}
        </div>
    </div>
  );
};

export default DashboardEventos;
