import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { consulta, getProductoId } from "../redux/actions";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

const DetailProducto = () => {
  const dispatch = useDispatch();
  const detalle = useSelector((state) => state.details);
  const imagenes = useSelector((state) => state.imagenesid);
  const [ordenimg, setOrdenimg] = useState("");
  
  let [email, setEmail] = useState("");
  let [nombre, setNombre] = useState("");
  let [mensaje, setMensaje] = useState("");

  const params = useParams();
  const id = params.id;

  useEffect(() => {
    dispatch(getProductoId(id));
  }, [dispatch, id]);

  function handleImage(e) {
    setOrdenimg(e.target.src);
  }

  function handleEmail(e) {
    setEmail(e.target.value);
  }
  
  function handleNombre(e) {
    setNombre(e.target.value);
  }

  function handleMensaje(e) {
    setMensaje(`Consulta de ${detalle.nombre}: ${e.target.value}`);
  }

  function EnviarConsulta() {
    if (email && mensaje) {
      dispatch(consulta(email, nombre, mensaje));
      Swal.fire({
        icon: 'success',
        title: 'Enviado',
        text: 'Tu consulta ha sido enviada correctamente.',
      });
      setEmail("");
      setNombre("");
      setMensaje("");
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Por favor completa todos los campos requeridos.',
      });
    }
  }

  return (
    <section className="text-gray-600 body-font overflow-hidden">
      <div className="container px-5 py-24 mx-auto">
        <div className="lg:w-4/5 mx-auto flex flex-wrap">
          <div className="flex flex-col items-center dark:bg-main-dark">
            <div className="dark:bg-main-dark">
              <img
                src={ordenimg === "" ? imagenes[0] : ordenimg}
                alt="imagen del producto"
                width="550px"
                height="550px"
                className="dark:bg-main-dark"
              />
            </div>
            <div className="flex flex-row items-center gap-[20px]">
              {imagenes.map((d) => (
                <button
                  key={d}
                  onClick={handleImage}
                  className="hover:shadow-xl transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-100 mt-[10px]"
                >
                  <img
                    src={d}
                    alt="imagen del producto"
                    width="120px"
                    height="120px"
                  />
                </button>
              ))}
            </div>
          </div>
          <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
            <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">
              {detalle.nombre}
            </h1>
            <div className="pt-7 lg:pt-14 2xl:pt-14">
              <div className="mb-3 xl:mb-2">
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  className="p-1 w-full"
                  onChange={handleNombre}
                  value={nombre}
                  placeholder="Nombre y Apellido"
                />
              </div>
              <div className="mb-3 xl:mb-2">
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="p-1 w-full"
                  onChange={handleEmail}
                  value={email}
                  placeholder="Email"
                />
              </div>
              <div className="mb-2 xl:mb-2">
                <textarea
                  id="mensaje"
                  name="mensaje"
                  onChange={handleMensaje}
                  value={mensaje.replace(`Consulta de ${detalle.nombre}: `, "")}
                  className="p-1 w-full resize-none"
                  placeholder="Escribe tu mensaje"
                />
              </div>
              <button
                onClick={EnviarConsulta}
                className="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded"
              >
                Enviar consulta
              </button>
            </div>
            <div className="flex">
              <span className="title-font font-medium text-2xl text-gray-900">
                ${detalle.precio}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DetailProducto;
