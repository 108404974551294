import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createorUpdateProducto, getProductos } from "../redux/actions";
import Add from "../assets/Agregar.png";
import Cloudinary from "./Cloudinary";
import ModificarProducto from "./ModificarProducto";
import Swal from "sweetalert2";

const DashboardProductos = () => {
  const dispatch = useDispatch();

  const [showForm, setShowForm] = useState(false);
  const [secureURLs, setSecureURLs] = useState([]);
  const [errors, setErrors] = useState(false);


  const [addProducto, setAddProducto] = useState({
    id: "",
    nombre: "",
    imagenes: [],
    precio: 0,
  });

  function validarObjeto(addProducto) {
    const errores = {};

    if (
      typeof addProducto.nombre !== "string" ||
      addProducto.nombre.length > 50
    ) {
      errores.nombre =
        "El nombre debe ser una cadena de texto de 50 caracteres o menos.";
    }
   

    return errores;
  }

  function handleChange(e) {
    setAddProducto({
      ...addProducto,
      [e.target.name]: e.target.value,
    });

    // Validar cada vez que cambian los datos
    setErrors(
      validarObjeto({
        ...addProducto,
        [e.target.name]: e.target.value,
      })
    );
  }

  function handleSubmit(e) {
    e.preventDefault();
    const validationErrors = validarObjeto(addProducto);

    if (Object.keys(validationErrors).length === 0) {
      dispatch(createorUpdateProducto(addProducto));
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Producto agregado !",
        showConfirmButton: false,
        timer: 3000,
      }); // Si no hay errores, puedes despachar la acción para agregar el producto o cualquier otra lógica
      setAddProducto({ id: "", nombre: "", imagenes: [], precio: 0 }); // Resetea el formulario
      setErrors({});
    } else {
      setErrors(validationErrors);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Error, corrija los errores!",
        showConfirmButton: false,
        timer: 1000,
      })
    }
  }


  addProducto.imagenes = secureURLs;

  useEffect(() => {
    dispatch(getProductos());
  }, [dispatch]);
  return (
    <section className="text-gray-600 py-8 body-font flex flex-row mx-auto mb-40">
      <div className="mx-auto justify-start w-[20%] gap-6">
        <button
          onClick={() => setShowForm(true)}
          className="flex flex-row px-3 py-2 text-black transition-colors duration-300 transform rounded-lg hover:bg-gray-400 hover:text-white"
        >
          <img src={Add} alt="" className="h-[20px] mr-1" />
          <span className="mx-2 text-sm font-medium">Nuevo producto</span>
        </button>

        <button
          onClick={() => setShowForm(false)}
          className="flex flex-row px-3 py-2 text-black transition-colors duration-300 transform rounded-lg hover:bg-gray-400 hover:text-white"
        >
          <img src={Add} alt="" className="h-[20px] mr-1" />
          <span className="mx-2 text-sm font-medium">Modificar Producto</span>
        </button>
      </div>
      <div className="w-[80%]">
        {showForm ? (
          <div className="flex w-full justify-center my-24">
            <div className="w-[70%]">
              <h1 className="lg:text-3xl md:text-2xl sm:text-xl xs:text-xl font-serif font-extrabold mb-2 text-black">
                Nuevo producto
              </h1>
              <form onSubmit={handleSubmit}>
                <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-4 justify-center w-full">
                <div className="w-full  mb-4 mt-6">
                  <label for="" className="mb-2 text-sm text-black">
                    Titulo
                  </label>
                  <input
                    type="text"
                    name="nombre"
                    className="mt-2 p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                    onChange={(e) => handleChange(e)}
                    value={addProducto.nombre}
                    />
                  {errors.nombre && (
                      <p style={{ color: "red" }}>{errors.nombre}</p>
                    )}
                </div>

                <div className="w-full mb-4 lg:mt-6">
                  <label for="" className="text-sm  text-black">
                    Imagenes
                  </label>

                  <div>
                    <Cloudinary
                      secureURLs={secureURLs}
                      setSecureURLs={setSecureURLs}
                      />
                  </div>
                </div>
                <div className="w-full mb-4 lg:mt-6">
                  <label for="" className="text-sm text-black">
                  Precio
                  </label>
                  <input
                    type="number"
                    name="precio"
                    className="p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                    value={addProducto.precio}
                    onChange={handleChange}
                    />
                  {errors.precio && (
                      <p style={{ color: "red" }}>{errors.precio}</p>
                    )}
                </div>                
                </div>
                <div className="w-full rounded-lg bg-blue-500 mt-4 text-white text-lg font-semibold">
                  <button type="submit" className="w-full p-4">
                    Guardar cambios
                  </button>
                </div>
              </form>
              </div>
          </div>
        ) : (
          <ModificarProducto/>
        )}
      </div>
    </section>
  );
};

export default DashboardProductos;
