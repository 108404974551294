import React from "react";
import logo from "../assets/logo.png";
import whatsapp from "../assets/whatsapp.png";
import instagram from "../assets/instagram.png";
import facebook from "../assets/facebook.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="text-gray-600 body-font">
      <div className="w-full h-px max-w-6xl mx-auto my-12 bg-gray-400"></div>
      <div className="container px-5 mt-8 mb-8 mx-auto flex flex-row">
        <a href="/" className="flex items-center">
          <img src={logo} alt="logo" className="h-10 md:h-20" />
        </a>
        <div className="grid grid-cols-2 gap-4 w-full text-center">
          <li className="list-none">
            <Link
              className="text-sm md:text-lg font-semibold hover:text-gray-900 hover:font-bold"
              to=""
            >
              Inicio
            </Link>
          </li>
          <li className="list-none">
            <Link
              className="text-sm md:text-lg font-semibold hover:text-gray-900 hover:font-bold"
              to=""
            >
              Eventos
            </Link>
          </li>
          <li className="list-none">
            <Link
              className="text-sm md:text-lg font-semibold hover:text-gray-900 hover:font-bold"
              to={"/Nosotros"}
            >
              Nosotros
            </Link>
          </li>
          <li className="list-none">
            <Link
              className="text-sm md:text-lg font-semibold hover:text-gray-900 hover:font-bold"
              to=""
            >
              Productos
            </Link>
          </li>
          <li className="list-none">
            <Link
              className="text-sm md:text-lg font-semibold hover:text-gray-900 hover:font-bold"
              to=""
            >
              Certificado médico
            </Link>
          </li>
        </div>
      </div>
      <div className="bg-gray-100 pt-4">
        <div className="container mx-auto pb-4 px-5 flex flex-wrap md:flex-col flex-row">
          <p className="text-sm inline-flex items-center text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
            © 2024 Abraham Molina
            <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
              <a
                className="ml-5 mr-5"
                target="_blank"
                rel="noreferrer" // Añadir rel="noreferrer"
                href="https://api.whatsapp.com/send?phone=543834527609&text=%C2%A1Buenos%20dias%20Juan!%20Soy"
              >
                <img src={whatsapp} className="h-6 md:h-10" alt="logo" />
              </a>
              <a
                className="ml-5 mr-5"
                target="_blank"
                rel="noreferrer" // Añadir rel="noreferrer"
                href="https://www.facebook.com/CATArunnn?locale=es_LA"
              >
                <img src={facebook} className="h-6 md:h-10" alt="logo" />
              </a>
              <a
                className="ml-5 mr-5"
                target="_blank"
                rel="noreferrer" // Añadir rel="noreferrer"
                href="https://www.instagram.com/catarun_?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              >
                <img src={instagram} className="h-6 md:h-10" alt="logo" />
              </a>
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
