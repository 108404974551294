import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
  className="flex items-center justify-center h-12 w-40 font-semibold text-white bg-green-600 rounded-md shadow-lg transition duration-300 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
  onClick={() => loginWithRedirect()}
>
  Ingresar
</button>

  );
};
