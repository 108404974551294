import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { LogoutButton } from "./Logout";
import { getCorredor } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import perfil from "../assets/perfil.png";
import Dash from "../assets/dashboard.png";
import Res from "../assets/resultados.png";
import Perfil from "./Perfil.jsx";
import Resultados from "./Resultados";
import { Link } from "react-router-dom";

export const Profile2 = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useAuth0();
  const corredor = useSelector((state) => state.corredor);
  const [show, setShow] = useState("");
  

  function changeViewPerfil(){
    setShow("Perfil")
  }
  function changeViewResultados(){
    setShow("Resultados")
  }

  useEffect(() => {
    if(isAuthenticated){
      dispatch(getCorredor(user.email));
    }
  }, [dispatch]);

  return isAuthenticated ? (
    <div className="flex flex-col lg:flex-row h-full">
      <aside className="flex flex-col w-full lg:w-64 bg-white border-b lg:border-r p-4">
        <p className="text-2xl">Hola {user.given_name}!</p>
        <img
          src={user.picture}
          alt="avatar"
          className="h-[150px] w-[150px] rounded-full mx-auto"
        />
        <nav className="mt-4 space-y-2">
          <button onClick={() => setShow("Perfil")} className="flex items-center p-2 w-full text-left hover:bg-gray-200">
            <img src={perfil} alt="Perfil" className="h-5 mr-2" />
            Perfil
          </button>
          <button onClick={() => setShow("Resultados")} className="flex items-center p-2 w-full text-left hover:bg-gray-200">
            <img src={Res} alt="Resultados" className="h-5 mr-2" />
            Resultados
          </button>
          {corredor?.tipo === "Admin" && (
            <Link to="/Dashboard/Eventos">
              <button className="flex items-center p-2 w-full text-left hover:bg-gray-200">
                <img src={Dash} alt="Dashboard" className="h-5 mr-2" />
                Dashboard
              </button>
            </Link>
          )}
        </nav>
        <LogoutButton />
      </aside>
      <main className="flex-1 p-4 lg:p-8">
        {show === "Perfil" ? <Perfil user={user} corredor={corredor} /> : <Resultados />}
      </main>
    </div>
  ) : (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="max-w-md mx-auto text-center bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-9xl font-bold text-orange-600">404</h1>
        <h2 className="text-4xl font-bold">Oops! Página No Encontrada</h2>
        <p className="text-lg text-gray-600">Inicia sesión para acceder a esta página.</p>
        <Link to="/" className="mt-4 inline-block bg-orange-600 text-white py-2 px-4 rounded">
          Volver al inicio
        </Link>
      </div>
    </div>
  );
};