import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2'
import { upgDniCorredor } from "../redux/actions";

const Perfil = ({ user, corredor }) => {
  const dispatch = useDispatch();
  const [addCorredor, setAddCorredor] = useState({
    nombre: corredor?.nombre || "",
    apellido: corredor?.apellido || "",
    sexo: corredor?.sexo || "",
    DNI: corredor?.dni || ""
  });
  function handleChange(e) {
      setAddCorredor({
          ...addCorredor,
          [e.target.name]: e.target.value,
      });
  }

  function handleSubmit(e) {
      e.preventDefault();
      dispatch(upgDniCorredor(user.email, addCorredor));
      Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Los cambios han sido guardados",
          showConfirmButton: false,
          timer: 1500
      });
      setAddCorredor({
          nombre: "",
          apellido: "",
          sexo: "",
          DNI: ""
      });
  }

  return (
      <section className="py-10 bg-white">
          <div className="container mx-auto flex flex-col items-center">
              <div className="w-full max-w-lg shadow-2xl p-6 rounded-xl bg-white">
                  <h1 className="text-3xl font-serif font-extrabold mb-4 text-black">Perfil</h1>
                  <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                      <div className="flex flex-col md:flex-row gap-4">
                          <div className="flex-1">
                              <label className="mb-2 text-black">Nombre</label>
                              <input
                                  type="text"
                                  name="nombre"
                                  className="mt-2 p-4 w-full border-2 text-black border-gray-400 bg-white"
                                  placeholder="Nombre"
                                  onChange={handleChange}
                                  value={addCorredor.nombre}
                              />
                          </div>
                          <div className="flex-1">
                              <label className="mb-2 text-black">Apellido</label>
                              <input
                                  type="text"
                                  name="apellido"
                                  className="mt-2 p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                                  placeholder="Apellido"
                                  onChange={handleChange}
                                  value={addCorredor.apellido}
                              />
                          </div>
                      </div>

                      <div className="flex flex-col md:flex-row gap-4">
                          <div className="flex-1">
                              <label className="mb-2 text-black">Sexo</label>
                              <select 
                                  name="sexo" 
                                  onChange={handleChange}
                                  value={addCorredor.sexo}
                                  className="w-full text-grey border-2 rounded-lg p-4 text-black border-gray-400 bg-white">
                                  <option disabled value="">Selecciona sexo</option>
                                  <option value="Masculino">Masculino</option>
                                  <option value="Femenino">Femenino</option>
                              </select>
                          </div>
                          <div className="flex-1">
                              <label className="mb-2 text-black">DNI</label>
                              <input
                                  type="text"
                                  className="p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                                  name="DNI"
                                  onChange={handleChange}
                                  value={addCorredor.DNI}
                              />
                          </div>
                      </div>
                      
                      <button type="submit" className="w-full mt-4 rounded-lg bg-blue-500 text-white text-lg font-semibold p-4">
                          Guardar cambios
                      </button>
                  </form>
              </div>
          </div>
      </section>
  );
};

export default Perfil;