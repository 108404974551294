import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createEvento } from "../redux/actions";
import Cloudinary from "./Cloudinary";
import swal from "sweetalert2";

const PostEvento = ({eventos}) => {
  const dispatch = useDispatch();
  const [errorImg, setErrorImg] = useState(false);
  const [secureURLsMaps, setSecureURLsMaps] = useState([]);
  const [secureURLsMapsMapa, setSecureURLsMapsMapa] = useState([]);
  const [errors, setErrors] = useState({});
  const [img, setImg] = useState(eventos.imagenes);
  const [imgmapa, setImgMapa] = useState(eventos.imagenesmapas);


  const [addCorredor, setAddCorredor] = useState({
    id: eventos.id,
    titulo: eventos.titulo,
    imagenes: eventos.imagenes,
    imagenesmapas: eventos.imagenesmapas,
    urlInscripcion: eventos.urlInscripcion,
    fecha: eventos.fecha,
    ubicacion: eventos.ubicacion,
    distancia: eventos.distancia,
    desnivelPositivo: eventos.desnivelPositivo,
    consideracionesGenerales: eventos.consideracionesGenerales,
  });

  addCorredor.imagenes = img.concat(secureURLsMaps);
  addCorredor.imagenesmapas = imgmapa.concat(secureURLsMapsMapa);

  const handleDeleteImg = (urlToRemove) => {
    setImg((img) => img.filter((url) => url !== urlToRemove));
  };
  const handleDeleteImgMapa = (urlToRemove) => {
    setImgMapa((imgmapa) => imgmapa.filter((url) => url !== urlToRemove));
  };

  function validate(addCorredor) {
    let errors = {};

    if (addCorredor.titulo.length > 50) {
      errors.titulo = "Inserte un titulo menor a 50 caracteres";
    }
    if (addCorredor.titulo === "") {
      errors.titulo = "Inserte un nombre para el producto";
    } else if (!/^[a-zA-Z0-9ÁÉÍÓÚáéíóúñÑ\u002F\s]*$/.test(addCorredor.titulo)) {
      errors.titulo =
        "El Titulo solo puede contener letras, números, espacios y barras";
    }
    if (addCorredor.ubicacion.length > 30) {
      errors.ubicacion = "Inserte una Ubicacion menor a 30 caracteres";
    }
    if (addCorredor.ubicacion === "") {
      errors.ubicacion = "Inserte una ubicacion para el Evento";
    }
    if (addCorredor.urlInscripcion === "") {
      errors.ubicacion = "Inserte un Link de Inscripcion para el Evento";
    }
    if (addCorredor.distancia === "") {
      errors.ubicacion = "Inserte distancias para el Evento";
    }
    if (addCorredor.desnivelPositivo === "") {
      errors.ubicacion = "Inserte un desnivel Positivo para el Evento";
    }
    if (addCorredor.consideracionesGenerales === "") {
      errors.ubicacion =
        "Inserte una consideracion general (descripcion) para el Evento";
    }

    return errors;
  }

  function handleChange(e) {
    setAddCorredor({
      ...addCorredor,
      [e.target.name]: e.target.value,
    });
    setErrors(
      validate({
        ...addCorredor,
        [e.target.name]: e.target.value,
      })
    );
  }

  function postEvento() {
    if (addCorredor.imagenes.length === 0) {
      setErrorImg(!errorImg);
    }
    if (
      errors.titulo ||
      errors.urlInscripcion ||
      errors.fecha ||
      errors.ubicacion ||
      errors.distancia ||
      errors.desnivelPositivo ||
      errors.consideracionesGenerales ||
      addCorredor.titulo === "" ||
      addCorredor.urlInscripcion === "" ||
      addCorredor.fecha === "" ||
      addCorredor.ubicacion === "" ||
      addCorredor.distancia === 0 ||
      addCorredor.desnivelPositivo === 0 ||
      addCorredor.consideracionesGenerales === 0
    ) {
      swal.fire({
        position: "top-end",
        icon: "error",
        title: "Error, corrija los errores!",
        showConfirmButton: false,
        timer: 1000,
      });
    } else {
      dispatch(createEvento(addCorredor));
      swal.fire({
        position: "top-end",
        icon: "success",
        title: "Evento modificado !",
        showConfirmButton: false,
        timer: 3000,
      });
      setAddCorredor({
        titulo: "",
        imagenes: [],
        imagenesmapas: [],
        urlInscripcion: "",
        fecha: "",
        ubicacion: "",
        distancia: "",
        desnivelPositivo: "",
        consideracionesGenerales: "",
      });
    }
  }

  
return (
    <div className="flex w-full justify-center my-24">
      <div className="w-[70%]">
        <h1 className="lg:text-3xl md:text-2xl sm:text-xl xs:text-xl font-serif font-extrabold mb-2 text-black">
          Nuevo evento
        </h1>
        <form>
          <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-4 justify-center w-full">
            <div className="w-full  mb-4 mt-6">
              <label for="" className="mb-2 text-sm text-white">
                Titulo
              </label>
              <input
                type="text"
                name="titulo"
                className="mt-2 p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                onChange={(e) => handleChange(e)}
                value={addCorredor.titulo}
              />
            </div>

            <div className="w-full  mb-4 mt-6">
              <label for="" className="mb-2 text-sm text-white">
              Url de inscripción              </label>
              <input
                type="text"
                name="urlInscripcion"
                className="mt-2 p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                onChange={(e) => handleChange(e)}
                value={addCorredor.urlInscripcion}
              />
            </div>
            <div className="w-full  mb-4 mt-6">
              <label for="" className="mb-2 text-sm text-white">
                Fecha
              </label>
              <input
                type="date"
                name="fecha"
                className="mt-2 p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                onChange={(e) => handleChange(e)}
                value={addCorredor.fecha}
              />
            </div>
            <div className="w-full  mb-4 lg:mt-6">
              <label for="" className="text-sm text-white">
              Ubicación
              </label>
              <input
                type="text"
                name="ubicacion"
                className="mt-2 p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                onChange={(e) => handleChange(e)}
                value={addCorredor.ubicacion}
              />
            </div>
            <div className="w-full  mb-4 lg:mt-6">
              <label for="" className="text-sm text-white">
              Consideraciones generales
              </label>
              <input
                type="text"
                name="consideracionesGenerales"
                className="mt-2 p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                onChange={(e) => handleChange(e)}
                value={addCorredor.consideracionesGenerales}
              />
            </div>
            <div className="w-full  mb-4 lg:mt-6">
              <label for="" className="text-sm text-white">
                Desnivel positivo
              </label>
              <input
                type="text"
                name="desnivelPositivo"
                className="mt-2 p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                onChange={(e) => handleChange(e)}
                value={addCorredor.desnivelPositivo}
              />
            </div>
            <div className="w-full mb-4 lg:mt-6">
              <label for="" className="text-sm text-white">
                Distancia
              </label>
              <input
                type="text"
                className="p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                name="distancia"
                onChange={(e) => handleChange(e)}
                value={addCorredor.distancia}
              />
            </div>
            <div className="w-full mb-4 lg:mt-6">
              <label htmlFor="" className="text-sm text-white">
                Imagenes
              </label>
              <div className="grid grid-cols-3">
                {img.length > 1 ? (
                  img?.map((url, i) => {
                    return (
                      <div className="relative h-[90px] m-2">
                        <button
                          type="button"
                          onClick={() => handleDeleteImg(url)}
                          className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full text-xs"
                          aria-label="Eliminar imagen"
                        >
                          &times;
                        </button>
                        <img className="h-[80px]" src={url} key={i} alt="" />
                      </div>
                    );
                  })
                ) : (
                  <div className="relative h-[90px] m-2">
                    <button
                      type="button"
                      onClick={() => handleDeleteImg(img[0])}
                      className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full text-xs"
                      aria-label="Eliminar imagen"
                    >
                      &times;
                    </button>

                    <img className="h-[80px]" src={img[0]} key={img[0]} alt="" />
                  </div>
                )}
              </div>
              <div>
                <Cloudinary
                  secureURLs={secureURLsMaps}
                  setSecureURLs={setSecureURLsMaps}
                />
              </div>
            </div>

            <div className="w-full mb-4 lg:mt-6">
              <label htmlFor="" className="text-sm mt-2 text-white">
                Imagenes mapas
              </label>
              <div className="grid grid-cols-3">
                {imgmapa.length > 1 ? (
                  imgmapa?.map((url, i) => {
                    return (
                      <div className="relative h-[90px] m-2">
                        <button
                          type="button"
                          onClick={() => handleDeleteImgMapa(url)}
                          className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full text-xs"
                          aria-label="Eliminar imagen"
                        >
                          &times;
                        </button>

                        <img src={url} className="h-[80px] w-full" key={i} alt="" />
                      </div>
                    );
                  })
                ) : (
                  <div className="relative h-[90px] m-2">
                    <button
                      type="button"
                      onClick={() => handleDeleteImgMapa(imgmapa[0])}
                      className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full text-xs"
                      aria-label="Eliminar imagen"
                    >
                      &times;
                    </button>

                    <img
                      className="h-[80px]"
                      src={imgmapa[0]}
                      key={imgmapa[0]}
                      alt=""
                    />
                  </div>
                )}
              </div>
              <div>
                <Cloudinary
                  secureURLs={secureURLsMapsMapa}
                  setSecureURLs={setSecureURLsMapsMapa}
                />
              </div>
            </div>
          </div>
          <div className="w-full rounded-lg bg-blue-500 mt-4 text-white text-lg font-semibold">
            <button onClick={postEvento} className="w-full p-4">
              Guardar Cambios
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostEvento;
