import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DashDelDetail, getProductoId, getProductos } from '../redux/actions';
import PostProducto from './PostProducto';
import DashProducto from './DashProducto';

const ModificarProducto = () => {

  const dispatch = useDispatch();
  const id = useSelector((state) => state.dashid);
  const productos = useSelector((state) => state.details);
  const handleVolver = () => {
    dispatch(DashDelDetail())
  }
  

  useEffect(() => {
    dispatch(getProductos());
    dispatch(getProductoId(id));
    
  }, [dispatch, id]);

  return (
    <div>
      {productos.nombre ? (
        <div>
          <button onClick={handleVolver} className="rounded px-4 py-2 text-xs bg-orange-300 text-blue-100 hover:bg-orange-400 duration-300">Volver</button>
          <PostProducto
          productos={productos} />
        </div>
      ) : (
        <div >
          <DashProducto />
        </div>
      )}
    </div>
  )
}

export default ModificarProducto