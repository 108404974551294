import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import DashPreviewProducto from './DashPreviewProducto'

const DashProducto = () => {
    const [props, setProps] = useState([])
    const llamada = useSelector(state => state.productos)


    useEffect(() => {
        setProps([...llamada])
    },[llamada])


    

    return (
    <section className="grid md:grid-cols-2 gap-14"> 
    {props?.map((producto, index) => (
        <DashPreviewProducto producto={producto} key={index}/>        
    ))}
    </section>
  )
}

export default DashProducto