import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import logo from "../assets/logo.png";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "./Login";
import { Profile } from "./Profile";
import { useDispatch } from "react-redux";
import { createCorredor, getCorredor } from "../redux/actions";

const Navbar = ({ styles = {
  bmMenuWrap: {
    position: "fixed",
    top: 0,
    right: 0,
    height: "100%",
    zIndex: 1000,
  },
  bmMenu: {
    background: "rgba(254, 215, 170, 0.9)",
    padding: "2em 1em",
    fontSize: "1.15em",
  },
  bmItem: {
    color: "#000000",
    padding: "10px 0",
    textDecoration: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.5)",
  },
} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated, user } = useAuth0();

  const email = user?.email;
  const dispatch = useDispatch();

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      const bodydepost = {
        nombre: user.given_name,
        apellido: user.family_name,
        email: user.email,
        picture: user.picture,
      };
      dispatch(createCorredor(bodydepost));
    }
  }, [isAuthenticated, user, dispatch]);

  useEffect(() => {
    if (email) {
      dispatch(getCorredor(email));
    }
  }, [email, dispatch]);

  return (
    <header className="text-gray-600 body-font ">
      <div className="container mx-auto flex p-5 items-center justify-between">
        <Link to="/">
          <img src={logo} alt="logo" className="h-12" />
        </Link>
        
        {/* Navbar para pantallas grandes */}
        <div className="hidden md:flex md:mr-auto md:ml-auto flex-wrap items-center text-base justify-center" >
          <Link className="mr-10 hover:text-gray-900 hover:font-bold" to="">Inicio</Link>
          <Link className="mr-10 hover:text-gray-900 hover:font-bold" to="/Eventos">Eventos</Link>
          <Link className="mr-10 hover:text-gray-900 hover:font-bold" to="/Nosotros">Nosotros</Link>
          <Link className="mr-10 hover:text-gray-900 hover:font-bold" to="/Productos">Productos</Link>
          <Link to="https://drive.google.com/file/d/1EWO1zRUdChdGIRcpVIf7mYdrqP7xFWjB/view?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mr-10 hover:text-gray-900 hover:font-bold">Certificado médico</Link>
        </div>

        {/* Mostrar la imagen de perfil en pantallas grandes */}
        <div className="hidden md:flex items-center">
          {isAuthenticated ? <Profile /> : <LoginButton />}
        </div>

        {/* Navbar para pantallas pequeñas */}
        <div className="md:hidden flex items-center ml-auto"> 
          <button className="text-2xl text-orange-200 p-2 ml-4" onClick={() => setIsOpen(!isOpen)}>
            ☰
          </button>
        </div>

        <Menu
          styles={styles}
          right
          width={"60%"} // Ancho reducido
          isOpen={isOpen}
          onStateChange={({ isOpen }) => setIsOpen(isOpen)}
          customCrossIcon={false}
          customBurgerIcon={false}
        >
          <button className="text-lg text-gray-600 mb-4" onClick={closeMenu}>Cerrar</button>
          <Link className="text-black hover:text-gray-900 hover:font-bold" onClick={closeMenu} to="">Inicio</Link>
          <Link className="text-black hover:text-gray-900 hover:font-bold" onClick={closeMenu} to="/Eventos">Eventos</Link>
          <Link className="text-black hover:text-gray-900 hover:font-bold" onClick={closeMenu} to="/Nosotros">Nosotros</Link>
          <Link className="text-black hover:text-gray-900 hover:font-bold" onClick={closeMenu} to="/Productos">Productos</Link>
          <Link onClick={closeMenu} to="https://drive.google.com/file/d/1EWO1zRUdChdGIRcpVIf7mYdrqP7xFWjB/view?usp=drive_link" target="_blank" rel="noopener noreferrer" className="text-black hover:text-gray-900 hover:font-bold">Certificado médico</Link>

          {/* Mostrar botón de inicio de sesión en el menú móvil */}
          {isAuthenticated ? (
  <div onClick={closeMenu}  className="flex items-center space-x-2">
    
    <Profile  />
  </div>
) : (
  <LoginButton onClick={closeMenu}/>
)}
        </Menu>
      </div>
    </header>
  );
};

export default Navbar;
