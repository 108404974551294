import React, { useEffect } from "react";
import CartaProducto from "./CartaProducto";
import { useDispatch, useSelector } from "react-redux";
import { getProductos } from "../redux/actions";

export const Productos = () => {
  const dispatch = useDispatch();
  const producto = useSelector((state) => state.productos);

  useEffect(() => {
    dispatch(getProductos());
  }, [dispatch]);
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap -m-4">
          {producto &&
            producto.map((e) => {
              return <CartaProducto producto={e} />;
            })}
        </div>
      </div>
    </section>
  );
};
