import React from "react";
import { useDispatch } from "react-redux";
import { dashId, deleteEvento } from "../redux/actions";
import { SiGooglemaps } from "react-icons/si";
import Swal from "sweetalert2";

const DashPreviewEvento = ({ data }) => {
  const dispatch = useDispatch();
  let id = data.id
  const handleId = () => {
    dispatch(dashId(data.id));
  };

  const borrar = () => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sí, elimínalo!"
    }).then((result) => {
      if (result.isConfirmed) {
        
        dispatch(deleteEvento(id))
        Swal.fire({
          title: "Eliminado!",
          text: "Has eliminado el evento!",
          icon: "success"
        });
        window.location.reload()
      }
    });
  }

  
  if (data) {
    return (
      <div>
          <div className="relative overflow-hidden transition-transform h-auto w-auto duration-300 ease-in-out rounded-lg shadow-lg group hover:shadow-xl hover:-translate-y-2">
            <img
              src={data.imagenes}
              alt=""
              className="object-fill w-full h-96 group-hover:opacity-50 transition-opacity"
            />
            <div className="flex flex-col p-4 bg-background">
              <h3 className="text-l font-bold justify-center">{data.titulo}</h3>
              <div className="flex items-center justify-center text-sm text-muted-foreground">
                <SiGooglemaps className="w-4 h-4" />
                <p className="text-sm text-muted-foreground">
                  {data.ubicacion}
                </p>
              </div>
            </div>
            <div className="flex flex-row gap-2">
          <button 
          className="bg-blue-700 text-white w-full p-2 flex flex-row justify-center gap-2 items-center rounded-sm hover:bg-gray-900 duration-100 ease-in-out"
          id={data?.id}
          onClick={() => handleId()}
          >
          Modificar
        </button>
        <button 
        className="bg-red-500 text-white w-full p-2 flex flex-row justify-center gap-2 items-center rounded-sm hover:bg-black duration-100 ease-in-out"
        onClick={() => borrar()}
        >
          Eliminar
        </button>
        
      </div> 
          </div>
      </div>
    );
  }
};

export default DashPreviewEvento;
