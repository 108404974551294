import React, { useEffect } from "react";
import { getEventoId, getResultados } from "../redux/actions";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import primero from "../assets/1ro.png";
import segundo from "../assets/2do.png";
import tercero from "../assets/3ro.png";
import coronamasc from "../assets/1ro-gralMasc.png";
import coronafem from "../assets/1ro-gralFem.png";

const MasInfoEvento = () => {
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();
  const resultadosTotales = useSelector((state) => state.resultados);
  const evento = useSelector((state) => state.detailseventoid);
  const eventoimg = useSelector((state) => state.imageneseventoid);
  const eventoimgmapa = useSelector((state) => state.imageneseventomapaid);
  

  const [showModal, setShowModal] = React.useState(false);
  let date = new Date();
  let fechacomp = date.toISOString().split("T")[0];

  useEffect(() => {
    dispatch(getEventoId(id));
    dispatch(getResultados(id));
  }, [dispatch, id]);
  return evento.fecha < fechacomp ? (
    <div className="flex mt-6 w-full justify-center">
      <div className="flex -mx-4 -my-2 w-[80%] justify-center overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
            <table className="min-w-full divide-y justify-center divide-gray-200 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 px-2 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    <span>Nombre</span>
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    Tiempo
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    Dorsal
                  </th>

                  <th
                    scope="col"
                    className="px-2 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    Pos. General
                  </th>

                  <th
                    scope="col"
                    className="px-2 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    Pos. Sexo
                  </th>

                  <th
                    scope="col"
                    className="px-2 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    Pos. Categoria
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    Categoria
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    Localidad
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                {resultadosTotales?.length > 1 ? (
                  resultadosTotales?.map((e, i) => (
                    <tr key={i} className="text-white">
                      <td className="px-2 py-4 text-sm font-medium whitespace-nowrap">
                        <div>
                          <h2 className="font-medium text-white">
                            {e?.posicionGeneral === "1" ? (
                              e?.categoria.includes("Masculino") ? (
                                <div className="inline p2-3 py-1">
                                  {e?.nombreApellido}
                                  <img
                                    src={coronamasc}
                                    className="h-[20px] w-[20px] inline mr-3"
                                    alt="1ro"
                                  />
                                </div>
                              ) : e?.categoria.includes("Femenino") ? (
                                <div className="inline p2-3 py-1">
                                  <img
                                    src={coronafem}
                                    className="h-[20px] w-[20px] inline mr-3"
                                    alt="1ro"
                                  />
                                  {e?.nombreApellido}
                                </div>
                              ) : (
                                <div className="inline p2-3 py-1">
                                  {e?.nombreApellido}
                                </div>
                              )
                            ) : (
                              <div className="inline p2-3 py-1">
                                {e?.nombreApellido}
                              </div>
                            )}
                          </h2>
                        </div>
                      </td>
                      <td className="px-2 py-4 text-sm font-medium whitespace-nowrap">
                        <div className="inline px-2 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                          {e?.total}
                        </div>
                      </td>
                      <td className="px-2 py-4 text-sm whitespace-nowrap">
                        {e?.dorsal}
                      </td>
                      <td className="px-2 py-4 text-sm whitespace-nowrap">
                        {e?.posicionGeneral === "1" ? (
                          <div className="inline px-2 py-1">
                            <img
                              src={primero}
                              className="h-[20px] w-[20px] inline"
                              alt="1ro"
                            />
                            {e?.posicionGeneral}
                          </div>
                        ) : e?.posicionGeneral === "2" ? (
                          <div className="inline px-2 py-1">
                            <img
                              src={segundo}
                              className="h-[20px] w-[20px] inline"
                              alt="2do"
                            />
                            {e?.posicionGeneral}
                          </div>
                        ) : e?.posicionGeneral === "3" ? (
                          <div className="inline px-2 py-1">
                            <img
                              src={tercero}
                              className="h-[20px] w-[20px] inline"
                              alt="3ro"
                            />
                            {e?.posicionGeneral}
                          </div>
                        ) : (
                          <div className="inline px-2 py-1">
                            {e?.posicionGeneral}{" "}
                          </div>
                        )}
                      </td>
                      <td className="px-2 py-4 text-sm whitespace-nowrap">
                        {e?.posicionSexo === "1" ? (
                          <div className="inline px-2 py-1">
                            <img
                              src={primero}
                              className="h-[20px] w-[20px] inline"
                              alt="1ro"
                            />
                            {e?.posicionSexo}
                          </div>
                        ) : e?.posicionSexo === "2" ? (
                          <div className="inline px-2 py-1">
                            <img
                              src={segundo}
                              className="h-[20px] w-[20px] inline"
                              alt="2do"
                            />
                            {e?.posicionSexo}
                          </div>
                        ) : e?.posicionSexo === "3" ? (
                          <div className="inline px-2 py-1">
                            <img
                              src={tercero}
                              className="h-[20px] w-[20px] inline"
                              alt="3ro"
                            />
                            {e?.posicionSexo}
                          </div>
                        ) : (
                          <div className="inline px-2 py-1">
                            {e?.posicionSexo}{" "}
                          </div>
                        )}
                      </td>
                      <td className="px-2 py-4 text-sm whitespace-nowrap">
                        {e?.posicionCategoria === "1" ? (
                          <div className="inline px-2 py-1">
                            <img
                              src={primero}
                              className="h-[20px] w-[20px] inline"
                              alt="1ro"
                            />
                            {e?.posicionCategoria}
                          </div>
                        ) : e?.posicionCategoria === "2" ? (
                          <div className="inline px-2 py-1">
                            <img
                              src={segundo}
                              className="h-[20px] w-[20px] inline"
                              alt="2do"
                            />
                            {e?.posicionCategoria}
                          </div>
                        ) : e?.posicionCategoria === "3" ? (
                          <div className="inline px-2 py-1">
                            <img
                              src={tercero}
                              className="h-[20px] w-[20px] inline"
                              alt="3ro"
                            />
                            {e?.posicionCategoria}
                          </div>
                        ) : (
                          <div className="inline px-2 py-1">
                            {e?.posicionCategoria}{" "}
                          </div>
                        )}
                      </td>
                      <td className="px-2 py-4 text-sm whitespace-nowrap">
                        {e?.categoria}
                      </td>
                      <td className="px-2 py-4 text-sm whitespace-nowrap">
                        {e?.localidad}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-white">
                    <td className="px-2 py-4 text-sm font-medium whitespace-nowrap">
                      <div>
                        <h2 className="font-medium text-white">
                          {resultadosTotales[0]?.posicionGeneral === "1" ? (
                            resultadosTotales[0]?.categoria.includes(
                              "Masculino"
                            ) ? (
                              <div className="inline px-2 py-1">
                                {resultadosTotales[0]?.nombreApellido}
                                <img
                                  src={coronamasc}
                                  className="h-[20px] w-[20px] inline mr-3"
                                  alt="1ro"
                                />
                              </div>
                            ) : resultadosTotales[0]?.categoria.includes(
                                "Femenino"
                              ) ? (
                              <div className="inline px-2 py-1">
                                {resultadosTotales[0]?.nombreApellido}
                                <img
                                  src={coronafem}
                                  className="h-[20px] w-[20px] inline mr-3"
                                  alt="1ro"
                                />
                              </div>
                            ) : (
                              <>{resultadosTotales[0]?.nombreApellido}</>
                            )
                          ) : (
                            <></>
                          )}
                        </h2>
                      </div>
                    </td>
                    <td className="px-2 py-4 text-sm font-medium whitespace-nowrap">
                      <div className="inline px-2 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                        {resultadosTotales[0]?.total}
                      </div>
                    </td>
                    <td className="px-2 py-4 text-sm whitespace-nowrap">
                      {resultadosTotales[0]?.dorsal}
                    </td>
                    <td className="px-2 py-4 text-sm whitespace-nowrap">
                      {resultadosTotales[0]?.posicionGeneral === "1" ? (
                        <div className="inline px-3 py-1">
                          {" "}
                          <img
                            src={primero}
                            className="h-[20px] w-[20px] inline"
                            alt="1ro"
                          />
                          {resultadosTotales[0]?.posicionGeneral}{" "}
                        </div>
                      ) : resultadosTotales[0]?.posicionGeneral === "2" ? (
                        <div className="inline px-3 py-1">
                          {" "}
                          <img
                            src={segundo}
                            className="h-[20px] w-[20px] inline"
                            alt="2do"
                          />
                          {resultadosTotales[0]?.posicionGeneral}{" "}
                        </div>
                      ) : resultadosTotales[0]?.posicionGeneral === "3" ? (
                        <div className="inline px-3 py-1">
                          {" "}
                          <img
                            src={tercero}
                            className="h-[20px] w-[20px] inline"
                            alt="3ro"
                          />
                          {resultadosTotales[0]?.posicionGeneral}{" "}
                        </div>
                      ) : (
                        <div className="inline px-3 py-1">
                          {resultadosTotales[0]?.posicionGeneral}{" "}
                        </div>
                      )}
                    </td>
                    <td className="px-2 py-4 text-sm whitespace-nowrap">
                      {resultadosTotales[0]?.posicionSexo === "1" ? (
                        <div className="inline px-3 py-1">
                          {" "}
                          <img
                            src={primero}
                            className="h-[20px] w-[20px] inline"
                            alt="1ro"
                          />
                          {resultadosTotales[0]?.posicionSexo}{" "}
                        </div>
                      ) : resultadosTotales[0]?.posicionSexo === "2" ? (
                        <div className="inline px-3 py-1">
                          {" "}
                          <img
                            src={segundo}
                            className="h-[20px] w-[20px] inline"
                            alt="2do"
                          />
                          {resultadosTotales[0]?.posicionSexo}{" "}
                        </div>
                      ) : resultadosTotales[0]?.posicionSexo === "3" ? (
                        <div className="inline px-3 py-1">
                          {" "}
                          <img
                            src={tercero}
                            className="h-[20px] w-[20px] inline"
                            alt="3ro"
                          />
                          {resultadosTotales[0]?.posicionSexo}{" "}
                        </div>
                      ) : (
                        <div className="inline px-3 py-1">
                          {resultadosTotales[0]?.posicionSexo}{" "}
                        </div>
                      )}
                    </td>
                    <td className="px-2 py-4 text-sm whitespace-nowrap">
                      {resultadosTotales[0]?.posicionCategoria === "1" ? (
                        <div className="inline px-3 py-1">
                          {" "}
                          <img
                            src={primero}
                            className="h-[20px] w-[20px] inline"
                            alt="1ro"
                          />
                          {resultadosTotales[0]?.posicionCategoria}{" "}
                        </div>
                      ) : resultadosTotales[0]?.posicionCategoria === "2" ? (
                        <div className="inline px-3 py-1">
                          {" "}
                          <img
                            src={segundo}
                            className="h-[20px] w-[20px] inline"
                            alt="2do"
                          />
                          {resultadosTotales[0]?.posicionCategoria}{" "}
                        </div>
                      ) : resultadosTotales[0]?.posicionCategoria === "3" ? (
                        <div className="inline px-3 py-1">
                          {" "}
                          <img
                            src={tercero}
                            className="h-[20px] w-[20px] inline"
                            alt="3ro"
                          />
                          {resultadosTotales[0]?.posicionCategoria}{" "}
                        </div>
                      ) : (
                        <div className="inline px-3 py-1">
                          {resultadosTotales[0]?.posicionCategoria}{" "}
                        </div>
                      )}
                    </td>
                    <td className="px-2 py-4 text-sm whitespace-nowrap">
                      {resultadosTotales[0]?.categoria}
                    </td>
                    <td className="px-2 py-4 text-sm whitespace-nowrap">
                      {resultadosTotales[0]?.localidad}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-center">
          <div className="max-w-xl mb-6">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              {evento.titulo}{" "}
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
              {evento.consideracionesGenerales}
            </p>
          </div>
          <div className="grid gap-5 row-gap-8 sm:grid-cols-2">
            <div className="bg-white border-l-4 shadow-sm border-deep-purple-accent-400">
              <div className="h-full p-5 border border-l-0 rounded-r">
                <h6 className="mb-2 font-semibold leading-5">Ubicacion</h6>
                <p className="text-sm text-gray-900">{evento.ubicacion}</p>
              </div>
            </div>
            <div className="bg-white border-l-4 shadow-sm border-deep-purple-accent-400">
              <div className="h-full p-5 border border-l-0 rounded-r">
                <h6 className="mb-2 font-semibold leading-5">Fecha</h6>
                <p className="text-sm text-gray-900">{evento.fecha}</p>
              </div>
            </div>
            <div className="bg-white border-l-4 shadow-sm border-deep-purple-accent-400">
              <div className="h-full p-5 border border-l-0 rounded-r">
                <h6 className="mb-2 font-semibold leading-5">Distancias</h6>
                <p className="text-sm text-gray-900">{evento.distancia}</p>
              </div>
            </div>
            <div className="bg-white border-l-4 shadow-sm border-deep-purple-accent-400">
              <div className="h-full p-5 border border-l-0 rounded-r">
                <h6 className="mb-2 font-semibold leading-5">
                  Desnivel Positivo
                </h6>
                <p className="text-sm text-gray-900">
                  {evento.desnivelPositivo}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <img
            className="object-fill w-full h-[550px] rounded shadow-lg "
            src={eventoimg[0]}
            alt="eventoimagen"
          />
          <button
            className="bg-orange-200 text-white hover:bg-orange-400 mt-4 justify-center items-center font-bold uppercase text-sm px-5 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => setShowModal(true)}
          >
            Inscripcion
          </button>
          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-[90%] md:w-[50%] my-6 mx-auto max-w-7xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                      <h3 className="text-3xl font-semibold">
                        {evento.titulo}
                      </h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-1 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                          X
                        </span>
                      </button>
                    </div>
                    {/*body*/}
                    <iframe
                      className="h-[700px] "
                      title="Inscripcion"
                      src={evento.urlInscripcion}
                    ></iframe>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                      <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        Cerrar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
      </div>
      <div className="flex flex-col justify-center mt-16">
        <div className="max-w-xl mb-6">
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
            Mapas por distancia
          </h2>
        </div>
        <div className="grid grid-cols-3">
          {eventoimgmapa &&
            eventoimgmapa.map((e, i) => (
              <div key={i} className="w-full max-w-xs overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800">
                <img className="object-fill w-full" src={e} alt="avatar" />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MasInfoEvento;
