import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/store";
// import * as dotenv from 'dotenv'
import { Auth0Provider } from '@auth0/auth0-react'
import "./index.css";
import App from "./App";
import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_API;
let dominio = process.env.REACT_APP_AUTH0DOMAIN 
let clientId = process.env.REACT_APP_AUTH0CLIENTID

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <Auth0Provider 
          domain={dominio}
          clientId={clientId}
          redirectUri={window.location.origin} >
      <App />
      </Auth0Provider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
