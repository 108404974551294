import React from 'react'
import Rutas from './rutas/Rutas'
import { useAuth0 } from '@auth0/auth0-react';


function App() {
  const { isLoading } = useAuth0();
  
    if (isLoading) {
      return (
      <div className='h-full w-full flex items-center justify-center'>
        <div className="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-blue-600 " />
      </div> )
    }
  return (
    <div>
      <Rutas/>
    </div>
  )
}

export default App