import React from "react";
import powerade from "../assets/powerade.png";
import Rodeo from "../assets/ElRodeo.png";
import Confecat from "../assets/Confecat.png";
import Taxnara from "../assets/Taxnara.png";
import Sportotal from "../assets/Sportotal.png";

const Sponsors = () => {
  return (
    <div className="mb-20 mt-10 grid select-none grid-cols-3 md:grid-cols-5 gap-2 md:gap-20">
      <a
        className="company-link group relative flex h-28 items-center justify-center overflow-hidden md:h-22"
        title="Visita la página del patrocinador Powerade"
        href="https://www.coca-cola.com/ar/es/brands/powerade"
        target="_blank" rel="noopener noreferrer"
      >
        <img src={powerade} className="w-60" alt="imagenes sponsors" />
      </a>
      <a
        className="company-link group relative flex h-28 items-center justify-center overflow-hidden md:h-22"
        title="Visita la página del patrocinador El Rodeo Alfajores"
        href="https://www.instagram.com/elrodeoalfajores/?hl=es"
         target="_blank" rel="noopener noreferrer"
      >
        <img src={Rodeo} className="w-60" alt="imagenes sponsors" />
      </a>
      <a
        className="company-link group relative flex h-28 items-center justify-center overflow-hidden md:h-22"
        title="Visita la página del patrocinador Confecat"
        href="https://www.confecat.com.ar/"
         target="_blank" rel="noopener noreferrer"
      >
        <img src={Confecat} className="w-60" alt="imagenes sponsors" />
      </a>
      <a
        className="company-link group relative flex h-28 items-center justify-center overflow-hidden md:h-22"
        title="Visita la página del patrocinador Taxnara Diseños"
        href="https://www.instagram.com/taxnaradisenos/?hl=es"
         target="_blank" rel="noopener noreferrer"
      >
        <img src={Taxnara} className="w-60" alt="imagenes sponsors" />
      </a>
      <a
        className="company-link group relative flex h-28 items-center justify-center overflow-hidden md:h-22"
        title="Visita la página del patrocinador Sportotal"
        href="https://www.sportotal.com.ar/"
         target="_blank" rel="noopener noreferrer"
      >
        <img src={Sportotal} className="w-60" alt="imagenes sponsors" />
      </a>
    </div>
  );
};

export default Sponsors;
